import * as React from 'react';
import {
    Box,
    Button, CircularProgress,
    Container,
    Paper,
    Slide,
    styled,
    TextField,
    Typography
} from "@mui/material";
import {blue, green} from "@mui/material/colors";
import {useFormik} from "formik";
import {object, string} from "yup";
import axios from "axios";

const Item = styled(Box)(({theme}) => ({
    backgroundColor: blue[100],
    padding: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const PaperItem = styled(Paper)(({theme}) => ({
        backgroundColor: blue[100],
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(10),
        height: '100%',
        width: '100%',
    }
));

const validationSchema = object({
        name: string().required('Name is required'),
        email: string().email('Please enter a valid email address').required('Email is required'),
        message: string().required('Message is required'),
    }
);

interface ContactFormData {
    name: string;
    email: string;
    message: string;
}

const initialValues: ContactFormData = {
    name: '',
    email: '',
    message: '',
};

export default function ContactForm() {
    const timer = React.useRef<number>();
    const [clicked, setClicked] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const buttonSx = {
        ...(submitted && {
            bgcolor: blue[100],
            '&:hover': {
                bgcolor: blue[500],
            },
        }),
    };

    React.useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    const handleClick = () => {
        setClicked((prev) => !prev);
    };

    const handleContactFormClick = () => {
        if (!loading) {
            setLoading(true);
            timer.current = window.setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values: ContactFormData) => {
            try {
                const url = process.env.REACT_APP_SENDMAIL_URL || 'http://127.0.0.1:5001/corporate-website-387714/us-central1/sendMail/';
                await axios.post(url, JSON.stringify(values), {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                setSubmitted(true);
            } catch (error: any) {
                console.log(error);
                if (error.response) {
                    console.log(error.response.body);
                }
            }
        }
    });

    const Form = (
        <PaperItem>
            <Typography variant={'h6'} sx={{mb: 1}}>For Questions or Quotes:</Typography>
            <Typography variant={'body2'} sx={{mb: 1}}>Please fill out the form below and we will get back to you as
                soon as
                possible.</Typography>
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    fullWidth
                    id="name"
                    name="name"
                    label="Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    sx={{mb: 1}}
                />
                <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    sx={{mb: 1}}
                />
                <TextField
                    fullWidth
                    id="message"
                    name="message"
                    label="Message"
                    multiline
                    rows={4}
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    error={formik.touched.message && Boolean(formik.errors.message)}
                    helperText={formik.touched.message && formik.errors.message}
                    sx={{mb: 1}}
                />
                <Button variant={'contained'} size={'large'} type="submit" sx={buttonSx} onClick={handleContactFormClick}>Submit</Button>
                {loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: green[500],
                            position: 'absolute',
                            top: '75%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
            </form>
        </PaperItem>
    );

    return (
        <Container component={'div'} sx={{mt: 10}}>
            <Typography variant="h4" component="div" gutterBottom={true} align={'center'}>
                Contact Us
            </Typography>
            {!clicked && (
                <Item>
                    <Typography variant={'body1'} sx={{mb: 10}}> Let us tailor a service package that meets your needs.
                        Tell
                        us a little
                        about your business, and we will get back to you with some ideas as soon as
                        possible. </Typography>
                    <Button variant={'contained'} size={'large'} onClick={handleClick}>For Questions or Quotes:</Button>
                </Item>
            )}
            {!submitted && (
                <Item>
                    <Slide direction={'right'} in={clicked} mountOnEnter unmountOnExit>
                        {Form}
                    </Slide>
                </Item>
            )}
            {submitted && (
                <Item>
                    <Typography variant={'h6'} sx={{mb: 1}}>Thank you for your submission!</Typography>
                    <Typography variant={'body2'} sx={{mb: 1}}>We will get back to you as soon as possible.</Typography>
                    <Button variant={'contained'} size={'large'} href={'/'}>Home</Button>
                </Item>
            )}
        </Container>
    )
}
