import * as React from 'react';
import {AppBar, Box, Button, Container, IconButton, Toolbar, Typography, Menu, MenuItem} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import logo from "../OPENFRAMEWORK.png";
import {grey} from "@mui/material/colors";
import Link from "@mui/material/Link";


const pages = ['services', 'contact'];
const mobilePages = ['services', 'contact'];

export default function NavigationBar() {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar position={'static'} sx={{backgroundColor: 'white'}}>
            <Container maxWidth={'xl'}>
                <Toolbar disableGutters={true}>
                    <Typography
                        variant={'h6'}
                        noWrap={true}
                        component={'a'}
                        href={'/'}
                        sx={{
                            mr: '10em',
                            display: {xs: 'none', md: 'flex'},
                            fontFamily: 'monospace',
                            fontSize:18,
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: grey[900],
                            textDecoration: 'none',
                        }}
                    >
                        OPENFRAMEWORKS LTD
                    </Typography>
                    <Box sx={{
                        flexGrow: 1,
                        display: {xs: 'flex', md: 'none'},
                    }}>
                        <IconButton
                            size="large"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}
                        >
                        {
                            mobilePages.map((page) => (
                                <MenuItem key={page} onClick={handleCloseNavMenu}>
                                    <Link
                                        href={`/${page}`}
                                        color={'inherit'}
                                        underline={'none'}
                                        >
                                    <Typography textAlign={'center'}>{page}</Typography>
                                    </Link>
                                </MenuItem>
                            ))
                        }
                        </Menu>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: {xs: 'none', md: 'flex'} }}>
                        {pages.map((page) => (
                            <Button
                                key={page}
                                href={`/${page}`}
                                sx={{ my: 4, color: grey[900], display: 'block', mr: '4em'}}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <Link
                            href={'/'}
                            color={'inherit'}
                            underline={'none'}
                            >
                        <img src={logo} alt="logo" width={150} className={'logo'}/>
                        </Link>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
