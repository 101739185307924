import * as React from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia, Popover, styled,
    Typography
} from "@mui/material";
import {blue} from "@mui/material/colors";
import qaImage from "../qa.jpg"

const Item = styled(Card)(({theme}) => ({
    backgroundColor: blue[100],
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    width: '100%',
    height: '100%',
}));

export default function QualityAssurance() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(null);

    const handleClick =
        () =>
            (event: React.MouseEvent<HTMLButtonElement>) => {
                setAnchorEl(event.currentTarget);
            };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    }

    const openPopover = Boolean(anchorEl);
    const id = openPopover ? 'qa' : undefined;

    return (
        <Box sx={{flexGrow: 1, mt: 5, ml: 1}}>
            <Item>
                <CardMedia
                    alt="Contemplative Reptile"
                    component={'img'}
                    sx={{height: 140}}
                    image={qaImage}
                    title="Contemplative Reptile"
                />
                <CardContent>
                    <Typography gutterBottom={true} variant="h5" textAlign={'center'}>
                        Test Automation
                    </Typography>
                    <Typography>
                        Our Test Automation services helps businesses streamline their software testing
                        processes, reduce manual effort, and accelerate product development. <br/> Leveraging state-of-the-art
                        automation tools and technologies, we ensure a robust, efficient, and comprehensive testing
                        approach that aligns with your software's complexity and business requirements.
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button aria-describedby={'qa'} size="small" type={'button'}
                            onClick={handleClick()}>More</Button>
                    <Popover
                        id={id}
                        open={openPopover}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Item>
                            <Typography sx={{p: 2}} variant={'h6'} textAlign={'center'}>Test Automation</Typography>
                            <Typography textAlign={'left'}>

                                <strong>Key Features:</strong>
                                <br/>
                                <br/>
                                <strong>Automated Functional Testing:</strong> We automate repetitive, labor-intensive functional tests
                                to increase speed and efficiency, ensuring your software performs exactly as it should
                                across different environments and user conditions.
                                <br/>
                                <br/>
                                <strong>Regression Testing:</strong> Our services include automated regression testing to validate that
                                code changes have not impacted existing functionality, enabling your team to make
                                alterations fearlessly and frequently.
                                <br/>
                                <br/>
                                <strong>Performance Testing:</strong> We simulate various user loads and stress conditions to identify
                                bottlenecks, ensuring your software can handle real-world usage and maintain its
                                performance under pressure.
                                <br/>
                                <br/>
                                <strong>Integration Testing:</strong> We automate tests for system integration points to validate smooth
                                and cohesive interactions between different software modules.
                                <br/>
                                <br/>
                                <strong>Continuous Testing:</strong> Integrated with CI/CD pipelines, we provide continuous testing
                                solutions that allow your development team to detect and resolve defects in real-time
                                during the development process.
                                <br/>
                                <br/>
                                <br/><strong>Benefits:</strong>
                                <br/>
                                <br/>
                                <strong>Speed and Efficiency:</strong> Automated tests run faster and can be executed frequently,
                                speeding up the testing cycle and software development process.
                                <br/>
                                <br/>
                                <strong>Cost-effective:</strong> By reducing manual effort, automation significantly lowers the cost of
                                testing over time.
                                <br/>
                                <br/>
                                <strong>Improved Accuracy:</strong> Automated tests eliminate the possibility of human error, ensuring
                                more precise and reliable testing results.
                                <br/>
                                <br/>
                                <strong>Greater Coverage:</strong> Test automation allows for a higher volume and variety of tests,
                                covering more features and workflows in less time.
                                <br/>
                                <br/>
                            </Typography>
                            <Typography textAlign={'center'}>
                                <strong>
                                Let us be your partner in driving quality at speed. Trust our Software Test Automation
                                service to deliver a reliable, high-performing, and flawless software product.
                                </strong>
                            </Typography>
                            <Button variant={'contained'} sx={{mt: 2}} href={'contact'}>CONTACT US</Button>
                        </Item>
                    </Popover>
                </CardActions>
            </Item>
        </Box>
)
}
