import * as React from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import {
    Box,
    Typography
} from "@mui/material";
import FullStack from "./FullStack";
import DevOps from "./DevOps";
import QualityAssurance from "./QualityAssuarnce";

export default function OurServices() {
    return (
        <Box sx={{
            flexGrow: 1,
            mt: 5,
        }}
        >
            <Typography variant="h4" gutterBottom={true} align={'center'}>
                Our Services
            </Typography>
            <Grid2 container spacing={2}>
                <Grid2 xs={12} md={4}>
                    <FullStack/>
                </Grid2>
                <Grid2 xs={12} md={4}>
                    <DevOps/>
                </Grid2>
                <Grid2 xs={12} md={4}>
                    <QualityAssurance/>
                </Grid2>
            </Grid2>
        </Box>

)
}
