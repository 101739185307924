import * as React from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia, Popover, styled,
    Typography
} from "@mui/material";
import {blue} from "@mui/material/colors";
import fullStackImage from "../full-stack-dev.jpg";

const Item = styled(Card)(({theme}) => ({
    backgroundColor: blue[100],
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    width: '100%',
    height: '100%',
}));

export default function FullStack() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(null);

    const handleClick =
        () =>
            (event: React.MouseEvent<HTMLButtonElement>) => {
                setAnchorEl(event.currentTarget);
            };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    }

    const openPopover = Boolean(anchorEl);
    const id = openPopover ? 'fullstack' : undefined;

    return (
        <Box sx={{flexGrow: 1, mt: 5, ml: 1}}>
            <Item>
                <CardMedia
                    alt="Contemplative Reptile"
                    component={'img'}
                    sx={{height: 140}}
                    image={fullStackImage}
                    title="Contemplative Reptile"
                />
                <CardContent>
                    <Typography gutterBottom={true} variant="h5" textAlign={'center'}>
                        Full Stack Development
                    </Typography>
                    <Typography>
                        In today's dynamic digital landscape, having a robust online presence is more essential
                        than ever. <br/> Our Full Stack Development Services are designed to deliver comprehensive
                        solutions to meet this need, ensuring your business stays ahead of the curve.
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" aria-describedby={'fullstack'} type={'button'}
                            onClick={handleClick()}>More</Button>
                    <Popover
                        id={id}
                        open={openPopover}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    >
                        <Item>
                            <Typography sx={{p: 2}} variant={'h6'} textAlign={'center'}>Full Stack
                                Development</Typography>
                            <Typography sx={{mt: 1}} textAlign={'left'}>
                                We specialize in both front-end and back-end development, providing seamless
                                integration across all layers of web application development. <br/> <br/>This allows
                                us to efficiently design, develop, and maintain websites and web applications that are
                                scalable, secure, and highly responsive.
                                <br/>
                                <br/> <strong>Our services include:</strong> <br/>
                                <br/>
                                <strong>Front-end Development:</strong> We create intuitive and engaging user interfaces
                                using the latest technologies such as HTML5, CSS3, JavaScript, Typescript
                                and popular libraries and frameworks like React.js, Vue.js, and Angular.js.
                                <br/>
                                <br/>
                                <strong>Back-end Development:</strong> We build robust and scalable back-end systems
                                using the latest technologies such as Node.js, Python, and Java.
                                <br/>
                                <br/>
                                <strong>Database Development:</strong> We design and develop databases using MySQL,
                                MongoDB, and other popular database management systems.
                                <br/>
                                <br/>
                                <strong>API Development:</strong> We develop RESTful APIs that allow seamless
                                communication between the front-end and back-end of your web
                                application.
                                <br/>
                                <br/>
                                <strong>Web Application Development:</strong> We develop web applications that are
                                highly responsive, secure, and scalable.
                                <br/>
                                <br/>
                                <strong>Web Application Maintenance:</strong> We provide ongoing maintenance and
                                support services to ensure your web application continues to run
                                smoothly.
                                <br/>
                                <br/>
                            </Typography>
                            <Button variant={'contained'} sx={{mt: 2}} href={'contact'}>CONTACT US</Button>
                        </Item>
                    </Popover>
                </CardActions>
            </Item>
        </Box>
    )
}
