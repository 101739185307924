import { createTheme } from '@mui/material/styles';
import {blue, grey, red} from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: blue[500],
        },
        secondary: {
            main: grey[100],
        },
        error: {
            main: red.A400,
        },
    },
});

export default theme;
