import * as React from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia, Popover, styled,
    Typography
} from "@mui/material";
import {blue} from "@mui/material/colors";
import devOpsImage from "../devOps.jpg";

const Item = styled(Card)(({theme}) => ({
    backgroundColor: blue[100],
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    width: '100%',
    height: '100%',
}));

export default function DevOps() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(null);

    const handleClick =
        () =>
            (event: React.MouseEvent<HTMLButtonElement>) => {
                setAnchorEl(event.currentTarget);
            };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    }

    const openPopover = Boolean(anchorEl);
    const id = openPopover ? 'devOps' : undefined;

    return (
        <Box sx={{flexGrow: 1, mt: 5, ml: 1}}>
            <Item>
                <CardMedia
                    alt="Contemplative Reptile"
                    component={'img'}
                    sx={{height: 140}}
                    image={devOpsImage}
                    title="Contemplative Reptile"
                />
                <CardContent>
                    <Typography gutterBottom={true} variant="h5"  textAlign={'center'}>
                        DevOps Engineering
                    </Typography>
                    <Typography color="text.secondary">
                        Our DevOps services bridge the gap between development and operations, creating a
                        culture and environment where building, testing, and releasing software can happen
                        rapidly, frequently, and more reliably. We pride ourselves on enabling fast-paced,
                        efficient, and agile development that meets the demands of any business environment.
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button aria-describedby={'devops'} size="small" type={'button'} onClick={handleClick()}>More</Button>
                    <Popover
                        id={id}
                        open={openPopover}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    >
                        <Item>
                            <Typography sx={{p: 2}} variant={'h6'} textAlign={'center'}>DevOps Engineering</Typography>
                            <Typography sx={{mt: 1}} textAlign={'left'}>
                                <strong>Continuous Integration and Deployment (CI/CD):</strong> We leverage modern CI/CD
                                pipelines to automate your software delivery process. This ensures quick,
                                repeatable releases and helps in catching bugs early in the development cycle.
                                <br/>
                                <br/>
                                <strong>Infrastructure as Code (IaC):</strong> Our DevOps team implements IaC to manage and
                                provision your IT infrastructure, eliminating the need for manual configuration
                                and reducing the risk of potential human error.
                                <br/>
                                <br/>
                                <strong>Monitoring and Logging:</strong> We deploy sophisticated tools to monitor system
                                performance, track application logs, and enable prompt troubleshooting. This
                                helps us to maintain high availability and quickly react to any issues.
                                <br/>
                                <br/>
                                <strong>Cloud Services:</strong> We help you migrate to, build on, and optimize your cloud
                                services. Our team is proficient with major cloud providers such as AWS, Google
                                Cloud, and Azure.
                                <br/>
                                <br/>
                                <strong>Containerization and Orchestration:</strong> Using technologies like Docker and
                                Kubernetes, we create lightweight, scalable, and isolated environments for your
                                applications, streamlining deployment and scaling.
                                <br/>
                                <br/>
                                <strong>Configuration Management:</strong> Our team automates the configuration of your
                                infrastructure using tools like Ansible, Puppet, and Chef to maintain consistent
                                and reliable environments.
                                <br/>
                                <br/>
                                <strong>Security and Compliance:</strong> We follow DevSecOps approach to incorporate security at
                                every phase of your software lifecycle, ensuring your infrastructure,
                                applications, and data remain secure.
                                <br/>
                                <br/>
                            </Typography>
                            <Typography textAlign={'center'}>
                                <strong>
                                Our DevOps services are designed to reduce time-to-market, increase efficiency,
                                and improve the quality of software builds.<br/> Trust us to
                                accelerate your journey towards a robust and agile IT infrastructure.
                                </strong>
                            </Typography>
                            <Button variant={'contained'} sx={{mt: 2}} href={'contact'}>CONTACT US</Button>
                        </Item>
                    </Popover>
                </CardActions>
            </Item>
        </Box>
    )
}
