import * as React from 'react';
import {Container, Paper, styled, Typography} from "@mui/material";
import {blue} from "@mui/material/colors";

const Item = styled(Paper)(({theme}) => ({
    backgroundColor: blue[100],
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    elevation: 3,
}));

export default function PrivacyPolicy() {
    return (
        <Container sx={{mt: 5}}>
            <Item>
                <Typography variant={'h3'} textAlign={'center'} sx={{mt: 5}}>Privacy Policy</Typography>
                <ol>
                    <li>
                        <Typography variant={'h6'}>Introduction</Typography>
                        <Typography variant={'body1'}>Welcome to Openframeworks Ltd. We
                            respect your privacy and are committed to protecting your personal data. This privacy policy
                            will inform you how we handle your personal data, your privacy rights, and how the law
                            protects you. Please read this privacy policy carefully before using our service.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant={'h6'}>Personal data we collect</Typography>
                        <Typography variant={'body1'}>We collect personal data that you voluntarily provide to us when
                            you express an interest in obtaining information about us or our
                            products and services, participate in activities on our website or otherwise when you
                            contact us. The personal data we collect can include the following: Name, Email Address,
                            Contact Data, and other relevant data.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant={'h6'}>How we use personal data</Typography>
                        <Typography variant={'body1'}>We use the information we collect from you to Communicate with
                            you for customer service purposes, to provide you with IT consulting services, and for
                            marketing and promotional purposes.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant={'h6'}>Data Security</Typography>
                        <Typography variant={'body1'}>We have implemented appropriate technical and organizational
                            security measures designed to protect the security of any personal information we process.
                            However, please also remember that we cannot guarantee that the internet itself is 100%
                            secure.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant={'h6'}>Data Retention</Typography>
                        <Typography variant={'body1'}>We will only retain your personal data for as long as necessary
                            to fulfil the purposes we collected it for, including for the purposes of satisfying any
                            legal, accounting, or reporting requirements.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant={'h6'}>Your Data Protection Rights</Typography>
                        <Typography variant={'body1'}>You have the following data protection rights: You can request
                            access, correction, updates or deletion of your personal data. You can object to
                            processing of your personal data, ask us to restrict processing of your personal data or
                            request portability of your personal data. If we have collected and process your personal
                            data with your consent, then you can withdraw your consent at any time. Withdrawing your
                            consent will not affect the lawfulness of any processing we conducted prior to your
                            withdrawal, nor will it affect processing of your personal data conducted in reliance on
                            lawful processing grounds other than consent. You have the right to complain to a data
                            protection authority about our collection and use of your personal data. For more
                            information, please contact your local data protection authority.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant={'h6'}>Changes to this Privacy Policy</Typography>
                        <Typography variant={'body1'}>We reserve the right to update this privacy policy at any time,
                            and we will provide you with a new privacy policy when we make any substantial updates. We
                            may also notify you in other ways from time to time about the processing of your personal
                            data.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant={'h6'}>Contact Us</Typography>
                        <Typography variant={'body1'}>If you have any questions or concerns about our privacy policy,
                            please contact us:<br/>
                            <strong>Email:</strong> folarin.odeyemi@openframeworksltd.com <br/>
                            <strong>Phone:</strong> 07464473560
                        </Typography>
                    </li>
                </ol>
            </Item>
        </Container>
    )
}
