import * as React from 'react';
import {Box, Button, Typography} from "@mui/material";

export default function NotFound() {
    return (
        <Box alignContent={'center'} textAlign={'center'} sx={{ mt: 10}}>
            <Typography variant={'h2'} sx={{ mb: 2}}> Page Not Found </Typography>
            <Button variant={'contained'} href={'/'}> Go Home </Button>
        </Box>
    );
}
