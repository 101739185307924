import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import {blue} from "@mui/material/colors";

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" textAlign={'center'}>
            {'Copyright © '}
            <Link color="inherit" href="/">
                Openframeworks Ltd
            </Link>{' - All Rights Reserved'}{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function StickyFooter() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <Box
                component="footer"
                sx={{
                    py: 3,
                    px: 2,
                    mt: 'auto',
                    backgroundColor: blue[100]
                }}
            >
                <Container maxWidth="sm">
                    <Copyright />
                    <Typography variant="body2" color="text.secondary" textAlign={'center'} sx={{ fontSize: 24, mt: 2}}>
                        <Link color="inherit" href={"privacy"} underline={'none'}>
                            Privacy Policy
                        </Link>
                    </Typography>
                </Container>
            </Box>
        </Box>
    );
}
