import * as React from 'react';
import {Button, Container, Paper, Stack, styled, Typography} from "@mui/material";
import landingBoxImage from "../openframeworks-landing.jpg"
import {blue, grey} from "@mui/material/colors";

const Item = styled(Paper)(({theme}) => ({
    backgroundColor: blue[100],
    ...theme.typography.body2,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
    width: '100%',
}));

const MobileItem = styled(Paper)(({theme}) => ({
    backgroundColor: blue[100],
    ...theme.typography.body2,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
    width: '100%',
    position: 'relative',
    right: 8,
}));

const MobileImageItem = styled(Paper)(({theme}) => ({
        backgroundColor: grey[900],
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        width: 'auto',
        height: 'auto',
        elevation: 3,
    }
));

const ImageItem = styled(Paper)(({theme}) => ({
        backgroundColor: grey[900],
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        width: 'auto',
        height: 'auto',
        elevation: 3,
    }
));

export default function LandingBox() {
    return (
        <Container>
            <Stack direction={'row'} sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}, mt: 10}}>
                <ImageItem>
                <img src={landingBoxImage} alt="logo" width={400} className={'home-image'}/>
                </ImageItem>
                <Item>
                    <Typography variant={'h6'} sx={{color: grey[700]}}>Welcome</Typography>
                    <Typography variant={'h5'}>Professional</Typography>
                    <Typography variant={'h5'}>Technology</Typography>
                    <Typography variant={'h5'}>Assistance</Typography>
                    <Typography variant={'body2'}>We make technology accessible!</Typography>
                    <Button variant={'contained'} sx={{mt: 2}} href={'contact'}>BOOK NOW</Button>
                </Item>
            </Stack>

            <Stack direction={'column'} sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}, mt: 5}}>
                <Typography variant={'h6'}
                            sx={{
                                textAlign: 'center',
                                fontFamily: 'monospace',
                                fontSize: 18,
                                fontWeight: 700,
                                letterSpacing: '.3em',
                                color: grey[900],
                                textDecoration: 'none',
                            }}
                >OPENFRAMEWORKS LTD</Typography>
                <MobileImageItem>
                    <img src={landingBoxImage} alt="logo" width={'100%'}/>
                </MobileImageItem>
                <MobileItem>
                    <Typography variant={'h6'} sx={{color: grey[700]}}>Welcome</Typography>
                    <Typography variant={'h5'}>Professional</Typography>
                    <Typography variant={'h5'}>Technology</Typography>
                    <Typography variant={'h5'}>Assistance</Typography>
                    <Typography variant={'body2'}>We make technology accessible!</Typography>
                    <Button variant={'contained'} sx={{mt: 2}} href={'contact'}>BOOK NOW</Button>
                </MobileItem>
            </Stack>
        </Container>
    );
}
