import * as React from 'react';
import {Box, Button, Container, Paper, Stack, styled, Typography} from "@mui/material";
import {blue, grey} from "@mui/material/colors";

const StyledPaper = styled(Paper)(({theme}) => ({
    backgroundColor: blue[100],
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.primary,
    width: '100%',
    elevation: 2,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    height: '100%'
}));

const StyledPaperMobile = styled(Paper)(({theme}) => ({
    backgroundColor: blue[100],
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.primary,
    width: '100%',
    elevation: 2,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    position: 'relative',
    right: 15,
    height: '100%'
}));

export default function AboutUs() {

    return (
        <Container sx={{mt: 10, mb: 10}}>
            <Typography variant={'h4'} textAlign={'center'} gutterBottom={true}>About Us</Typography>
            <Box sx={{display: {xs: 'none', md: 'flex'}, flexGrow: 1}}>
                <Stack direction={'row'}>
                    <StyledPaper>
                        <Typography variant={'h6'} sx={{color: grey[700]}}>Technical Experience</Typography>
                        <Typography sx={{mb: 2}}>
                            We are well-versed in a variety of operating systems, networks, and databases. We work with
                            just about any technology that a small business would encounter. We use this expertise to
                            help customers with small to mid-sized projects.
                        </Typography>
                        <Button variant={'contained'} sx={{mt: 2}} href={'contact'}>BOOK ONLINE</Button>
                    </StyledPaper>
                    <StyledPaper>
                        <Typography variant={'h6'} sx={{color: grey[700]}}>High ROI</Typography>
                        <Typography sx={{mb: 2}}>
                            Do you spend most of your IT budget on maintaining your current system? Many companies find
                            that constant maintenance eats into their budget for new technology. By outsourcing your IT
                            management to us, you can focus on what you do best--running your business.
                        </Typography>
                        <Button variant={'contained'} sx={{mt: 2}} href={'services'}>SEE SERVICES</Button>
                    </StyledPaper>
                    <StyledPaper>
                        <Typography variant={'h6'} sx={{color: grey[700]}}>Satisfaction Guaranteed</Typography>
                        <Typography sx={{mb: 2}}>
                            The world of technology can be fast-paced and scary. That's why our goal is to provide an
                            experience that is tailored to your company's needs. No matter the budget, we pride
                            ourselves on providing professional customer service. We guarantee you will be satisfied
                            with our work.
                        </Typography>
                        <Button variant={'contained'} sx={{mt: 2}} href={'contact'}>CONTACT US</Button>
                    </StyledPaper>
                </Stack>
            </Box>
            <Box sx={{display: {xs: 'flex', md: 'none'}, flexGrow: 1}}>
                <Stack direction={'column'}>
                    <StyledPaperMobile>
                        <Typography variant={'h6'} sx={{color: grey[700]}}>Technical Experience</Typography>
                        <Typography sx={{mb: 2}}>
                            We are well-versed in a variety of operating systems, networks, and databases. We work with
                            just about any technology that a small business would encounter. We use this expertise to
                            help
                            customers with small to mid-sized projects.
                        </Typography>
                        <Button variant={'contained'} sx={{mt: 2}} href={'contact'}>BOOK ONLINE</Button>
                    </StyledPaperMobile>
                    <StyledPaperMobile>
                        <Typography variant={'h6'} sx={{color: grey[700]}}>High ROI</Typography>
                        <Typography sx={{mb: 2}}>
                            Do you spend most of your IT budget on maintaining your current system? Many companies find
                            that constant maintenance eats into their budget for new technology. By outsourcing your IT
                            management to us, you can focus on what you do best--running your business.
                        </Typography>
                        <Button variant={'contained'} sx={{mt: 2}} href={'services'}>SEE SERVICES</Button>
                    </StyledPaperMobile>
                    <StyledPaperMobile>
                        <Typography variant={'h6'} sx={{color: grey[700]}}>Satisfaction Guaranteed</Typography>
                        <Typography sx={{mb: 2}}>
                            The world of technology can be fast-paced and scary. That's why our goal is to provide an
                            experience that is tailored to your company's needs. No matter the budget, we pride
                            ourselves on providing professional customer service. We guarantee you will be satisfied
                            with our work.
                        </Typography>
                        <Button variant={'contained'} sx={{mt: 2}} href={'contact'}>CONTACT US</Button>
                    </StyledPaperMobile>
                </Stack>
            </Box>
        </Container>
    );
}
