import React from 'react';
import './App.css';
import NavigationBar from "./components/NavigationBar";
import Home from "./pages/Home";
import LandingBox from "./components/LandingBox";
import AboutUs from "./components/AboutUs";
import StickyFooter from "./components/StickyFooter";
import {Route, BrowserRouter as Router, Routes} from 'react-router-dom';
import Services from "./pages/Services";
import OurServices from "./components/OurServices";
import ContactUs from "./pages/ContactUs";
import ContactForm from "./components/ContactForm";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import NotFound from "./pages/NotFound";

function App() {
    return (
        <div>
            <NavigationBar/>
            <Router>
                <Routes>
                    <Route path={'/'} element={
                        <Home>
                            <LandingBox/>
                            <AboutUs/>
                            <OurServices/>
                            <ContactForm/>
                        </Home>
                    }/>
                    <Route path={'services'} element={
                        <Services>
                            <OurServices/>
                        </Services>
                    }/>
                    <Route path={'contact'} element={
                        <ContactUs>
                            <ContactForm/>
                        </ContactUs>
                    }/>
                    <Route path={'privacy'} element={<PrivacyPolicy/>}/>
                    <Route path={'*'} element={<NotFound/>}/>
                </Routes>
            </Router>
            <StickyFooter/>
        </div>
    );
}

export default App;
